.menu a:hover {
    @apply text-gray-300;
}


.left-arrow {
    cursor: url("../images/left_arrow.svg"), w-resize;
  }
  
  
  .right-arrow {
    cursor: url("../images/right_arrow.svg"), e-resize;
  }

/* purgecss start ignore */

.twic-blur {
    opacity: 0;
    will-change: opacity;
    transition: opacity 1s linear, transform .75s ease-out;
  }

  .twic-done {
    opacity: 1;
  }

/* purgecss end ignore */